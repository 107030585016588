import { AccounInfo } from "../Components/AccountInfo";
import Footer from "../Components/Footer";
import { Header } from "../Components/Header";
import { CurancyLK } from "../Components/CurrancyLK";
import { ChatButton } from "../Components/Chat";
import { ExchangeWindowLK } from "../Components/ExchangeWindowLK";
import { profileSwitch, store } from "../Store/Store";
import { useSelector } from "react-redux";
import { QuestionPop } from "../Components/QuestionPop";
import { useState } from "react";

export const Account = () => {
  store.dispatch(profileSwitch(true));
  const [openRules, setOpenRules] = useState(false);

  const languageStatus = useSelector((state) => state.currentLanguage);
  const themeStatus = useSelector((state) => state.currentTheme);

  return (
    <div>
      <div className={themeStatus == "dark" ? "mainBox" : "mainBoxLight"}>
        {" "}
        <div className="light x1"></div>
        <div className="light x3"></div>
        <div className="light x5"></div>
        <div className="light x7"></div>
        <div className="light x9"></div>
        <Header></Header>{" "}
        <div className="title mainExchangeTitle">
          {languageStatus ? "Account" : "Мой профиль"}
        </div>
        <div className="emailBox">
          <div style={{ color: "#ffffff", fontWeight: "bold" }}>E-MAIL:</div>
          <div>{localStorage.getItem("email")}</div>
        </div>
        <AccounInfo></AccounInfo>
        <CurancyLK></CurancyLK>
        <div className="accountExchanger">
          <ExchangeWindowLK
            openRules={(e) => setOpenRules(e)}
          ></ExchangeWindowLK>
        </div>
        <ChatButton></ChatButton>
      </div>{" "}
      <Footer></Footer>{" "}
      {openRules && <QuestionPop close={(e) => setOpenRules(e)} />}
    </div>
  );
};
