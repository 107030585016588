import { useEffect, useState } from "react";
import Footer from "../Components/Footer";
import { Header } from "../Components/Header";
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";
import { profileSwitch, store } from "../Store/Store";
import Exchange from "../Api/ExchangeApi/ExchangeApi";
import copyImage from "../icons/copy.png";
import { ContinueTransitionPop } from "../Components/ContinueTransitionPop";

export const TransitionStatus = () => {
  store.dispatch(profileSwitch(false));
  const [load, setLoad] = useState(false);
  const [status, setStatus] = useState();
  // const [addres, setAddres] = useState(
  //   localStorage.getItem("transactionAddres")
  // );
  // const [activaTransition, setActivaTransition] = useState(false);
  const transitionCoin = localStorage.getItem("transitionCoin");
  const getCoin = localStorage.getItem("getCoin");
  const [transaction, setTransaction] = useState({});
  const [continuePop, setContinuePop] = useState(false);

  const sumStatus = useSelector((state) => state.currentTransitionSum);
  const languageStatus = useSelector((state) => state.currentLanguage);

  store.dispatch(profileSwitch(false));

  const themeStatus = useSelector((state) => state.currentTheme);

  async function fetch() {
    if (
      transitionCoin === "BTC" &&
      (getCoin === "USD" || getCoin === "EUR" || getCoin === "RUB")
    ) {
      setTransaction(await Exchange.fetchBTCtoFIAT(sumStatus.sum));
    }

    if (
      transitionCoin === "TON" &&
      (getCoin === "USD" || getCoin === "EUR" || getCoin === "RUB")
    ) {
      await Exchange.checkFetchTONtoFIAT(setContinuePop);

      await Exchange.fetchTONtoFIAT(sumStatus.sum, setTransaction);
    }
  }

  function copyAddress(address) {
    navigator.clipboard.writeText(address);
  }

  useEffect(() => {
    setLoad(true);
    fetch();
    setLoad(false);
  }, []);

  useEffect(() => {
    if (transaction.status === "pending") {
      setStatus(languageStatus ? "Waiting for address" : "Ожидание адреса");
    }

    if (transaction.status === "transaction_waiting") {
      setStatus(
        languageStatus ? "Waiting for transaction" : "Ожидание транзакции"
      );
    }

    if (transaction.status === "done") {
      setStatus(languageStatus ? "Done" : "Готово");
    }

    if (transaction.status === "Created") {
      setStatus(languageStatus ? "Deal has been created" : "Сделка создана");
    }

    if (transaction.status === "Get") {
      setStatus(languageStatus ? "Funds received" : "Средства получены");
    }

    if (transaction.status === "Stop") {
      setStatus(languageStatus ? "Deal was canceled" : "Сделка отменена");
    }
  }, [languageStatus, transaction]);

  return (
    <div>
      <div className={themeStatus == "dark" ? "mainBox" : "mainBoxLight"}>
        <div className="light x1"></div>
        <div className="light x3"></div>
        <div className="light x5"></div>
        <div className="light x7"></div>
        <div className="light x9"></div>
        <Header></Header>
        <div className="exchangeInstruction">
          <div className="guidInstruction">
            <div className="guidNumber">1.</div>{" "}
            <div className="guidInstructionTitle">
              <div className="guidTitle exchangerGuidTitle">
                {languageStatus ? "Exchange pair" : "Обменная пара"}
              </div>{" "}
              <div className="guidInfo exchangerGuidInfo">
                {languageStatus
                  ? "Select the desired exchange pair and enter the amount to be exchanged\n\n⁃ When exchanging cryptocurrency to fiat: Enter the number of the card to which you want to receive funds \n\n⁃ When exchanging fiat to cryptocurrency: Enter the number of the card from which you want to transfer money and the address of your cryptocurrency wallet to receive the cryptocurrency."
                  : `Выберите желаемую обменную пару и введите сумму для обмена \n\n⁃ При обмене криптовалюты на фиат: Введите номер карты, на которую хотите получить денежные средства \n\n⁃ При обмене фиат на криптовалюту: Введите номер карты, с которой будете переводить деньги, и адрес вашего криптокошелька для получения криптовалюты`}
              </div>
            </div>
          </div>{" "}
          <div className="guidInstruction">
            <div className="guidNumber">2.</div>{" "}
            <div className="guidInstructionTitle">
              <div className="guidTitle exchangerGuidTitle">
                {languageStatus ? "Payment and reporting" : "Оплата и отчёт"}
              </div>
              <div className="guidInfo exchangerGuidInfo">
                {languageStatus
                  ? "Enter your e-mail: \n\n⁃ If you already have an account, please log in \n\n⁃ If you don't have an account, the system will automatically create one for you\n\nWait for the cryptocurrency wallet address or card number to appear\n\nTransfer funds to the specified wallet or card. \n\nIMPORTANT! The transfer amount must match the amount you specified. Otherwise, the transaction will fail and the funds will not be received. "
                  : "Укажите свою эл.почту: \n\n⁃ Если у вас уже есть аккаунт, авторизуйтесь \n\n⁃ Если аккаунта нет, система автоматически создаст его для вас\n\nДождитесь появления адреса криптокошелька или номера карты\n\nПереведите средства на указанный кошелек или карту. \n\nВАЖНО! Сумма перевода должна совпадать с указанной вами суммой. В противном случае транзакция не пройдет, и средства не поступят. "}
              </div>
            </div>
          </div>
          <div className="guidInstruction">
            <div className="guidNumber">3.</div>
            <div className="guidInstructionTitle">
              <div className="guidTitle exchangerGuidTitle">
                {" "}
                {languageStatus ? "Account" : "Личный кабинет"}
              </div>{" "}
              <div className="guidInfo exchangerGuidInfo">
                {languageStatus
                  ? "You can view the transaction status and exchange history through your personal cabinet.\n\nYou will find a letter with a password for login on the e-mail specified during the exchange."
                  : "Посмотреть статус транзакции и историю обменов можно через личный кабинет.\n\nНа эл. почте, указанной при обмене, вы найдете письмо с паролем для входа."}
              </div>
            </div>
          </div>{" "}
          <div className="guidInstruction">
            <div className="guidNumber">4.</div>{" "}
            <div className="guidInstructionTitle">
              <div className="guidTitle exchangerGuidTitle">
                {languageStatus ? "Check the balance" : "Проверьте баланс"}
              </div>{" "}
              <div className="guidInfo exchangerGuidInfo">
                {languageStatus
                  ? 'After changing the status of the transaction to "Funds Received", wait for the money to arrive on the cryptocurrency wallet or card you specified, depending on the type of exchange. The funds will arrive within a few minutes'
                  : 'После изменения статуса сделки на "Средства получены" ждите поступления денег на указанный вами криптокошелек или карту в зависимости от типа обмена. Средства поступят в течение нескольких минут'}
              </div>
            </div>
          </div>
        </div>
        <div className="transitionStatusBox">
          <div className="transitionTitle">
            {languageStatus
              ? "To complete the transaction, send the funds to this address:"
              : "Для осуществления операции отправьте средства на этот адрес:"}
          </div>
          <div className="transitionAddres">
            {load ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                  minWidth: "100%",
                }}
              >
                <ReactLoading
                  type="spinningBubbles"
                  color="white"
                  height={"50px"}
                  width={"50px"}
                />
              </div>
            ) : (
              <div>
                <div className="transitionAddressText">
                  {transaction.addres == null
                    ? languageStatus
                      ? "Waiting for wallet number"
                      : "Ожидание номера кошелька"
                    : transaction.addres}
                </div>
                {transaction.addres == null ? null : (
                  <button
                    className="copyImage"
                    onClick={() => {
                      copyAddress(transaction.addres);
                    }}
                  >
                    <img alt="" src={copyImage}></img>
                  </button>
                )}
              </div>
            )}
          </div>
          <div className="transitionAmount">
            {languageStatus
              ? "You're going to transfer: "
              : "Вы собираетесь перевести: "}
            <div style={{ color: "#ffffff" }}>
              {" "}
              {localStorage.getItem("coinAmount")}{" "}
              {localStorage.getItem("transitionCoin")}
            </div>
          </div>
          <div className="transitionStatus">
            {" "}
            {languageStatus ? "Transaction Status: " : "Статус транзакции: "}
            <div style={{ color: "#ffffff" }}>{status}</div>
          </div>
        </div>
      </div>{" "}
      {continuePop && (
        <ContinueTransitionPop
          sumStatus={sumStatus.sum}
          setTransaction={setTransaction}
          callback={(e) => setContinuePop(e)}
        />
      )}
      <Footer></Footer>
    </div>
  );
};
