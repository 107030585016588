import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const History = () => {
  const languageStatus = useSelector((state) => state.currentLanguage);

  const list = [
    {
      date: "03.04.23 16:28",
      fistValue: "USD",
      secondValue: "BTC",
      sum: "132",
      status: "завершено",
    },
    {
      date: "25.05.23 11:44",
      fistValue: "XRP",
      secondValue: "USDT",
      sum: "132",
      status: "активно",
    },
    {
      date: "28.05.23 12:30",
      fistValue: "SOL",
      secondValue: "XMR",
      sum: "132",
      status: "завершено",
    },
    {
      date: "28.05.23 12:30",
      fistValue: "SOL",
      secondValue: "XMR",
      sum: "132",
      status: "завершено",
    },
    {
      date: "28.05.23 12:30",
      fistValue: "SOL",
      secondValue: "XMR",
      sum: "132",
      status: "завершено",
    },
    {
      date: "28.05.23 12:30",
      fistValue: "SOL",
      secondValue: "XMR",
      sum: "132",
      status: "завершено",
    },
    {
      date: "28.05.23 12:30",
      fistValue: "SOL",
      secondValue: "XMR",
      sum: "132",
      status: "активно",
    },
  ];

  const [history, setHistoryList] = useState(list);

  useEffect(() => {
    let sorted = list.sort((a) => (a.status === "завершено" ? 1 : -1));
    setHistoryList([...sorted]);
  }, []);

  return (
    <div className="historyBox">
      <div>
        {" "}
        <div className="title historyTitle">
          {languageStatus ? "Transaction History" : "История сделок"}
        </div>{" "}
        <div className="historyList">
          {history.map((element) => {
            return (
              <div key={element} className="pairTitles historyPair">
                <div className="pairDate">{element.date}</div>
                <div className="pairValues">
                  <span>{element.fistValue}</span>
                  <div className="bulletBox">
                    <div className="line">
                      <div className="bullet"></div>
                    </div>
                  </div>
                  <span>{element.secondValue} </span>
                </div>
                <div className="pairSumBox">
                  <span className="pairFirstSum">{element.sum}</span>
                  <span className="pairSecondSum">{element.sum}</span>
                </div>
                <span>
                  Статус:{" "}
                  <span
                    style={{
                      color: "#ffffff",
                    }}
                  >
                    {element.status}
                  </span>{" "}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
